#skills {
  padding: 5rem 0;
}

.skills-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.skills-container {
  display: flex;
  animation: scroll 30s linear infinite;
}

.skill-item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3rem;
  width: 80px; // Increased to account for larger text
}

.skill-item img {
  width: 100%;
  height: 150px;
  object-fit: contain; // This ensures the entire icon is visible
}

.skill-item span {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  text-align: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

// Media query for smaller screens
@media (max-width: 768px) {
  .skill-item {
    width: 60px;
    margin: 0 0.5rem;
  }

  .skill-item img {
    width: 40px;
    height: 40px;
  }

  .skill-item span {
    font-size: 0.7rem;
  }
}
